import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TickerManager = () => {
  const [tickers, setTickers] = useState([]);
  const [newTicker, setNewTicker] = useState('');
  const [latestFiling, setLatestFiling] = useState('');

  useEffect(() => {
    fetchTickers();
  }, []);

  const fetchTickers = async () => {
    try {
      const response = await axios.get('https://www.haydenjin.com/api/tickers');
      setTickers(response.data);
    } catch (error) {
      console.error('Error fetching tickers:', error);
    }
  };

  const addTicker = async () => {
    try {
      await axios.post('http://172.16.1.224/api/tickers', {
        ticker_symbol: newTicker,
        cik: "0000000000" // Replace with actual CIK if you have it
      });
      fetchTickers();
      setNewTicker('');
    } catch (error) {
      console.error('Error adding ticker:', error);
    }
  };

  const updateTicker = async (tickerSymbol) => {
    try {
      await axios.put(`http://172.16.1.224/api/tickers/${tickerSymbol}`, {
        latest_filing: latestFiling
      });
      fetchTickers();
      setLatestFiling('');
    } catch (error) {
      console.error('Error updating ticker:', error);
    }
  };

  return (
    <div>
      <h1>Ticker Manager</h1>
      <ul>
        {tickers.map((ticker, index) => (
          <li key={index}>
            {ticker.ticker_symbol} - {ticker.latest_filing}
          </li>
        ))}
      </ul>
      <input
        type="text"
        value={newTicker}
        onChange={(e) => setNewTicker(e.target.value)}
        placeholder="Enter ticker"
      />
      <button onClick={addTicker}>Add Ticker</button>
    </div>
  );
};

export default TickerManager;