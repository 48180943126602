import './App.css';
import TickerManager from './components/TickerManager';

function App() {
  return (
    <div className="App">
      <TickerManager />
    </div>
  );
}

export default App;
